<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：学会管理 > <b>部门小组管理</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="department-info">
            <div class="department-condition">
                <div class="flex-center">
                    <el-input v-model="search_content" placeholder="请输入部门小组名称"></el-input>
                    <el-button type="primary" @click="search">搜索</el-button>
                </div>
                <el-button type="primary" @click="showEditDialog(false)">添加小组</el-button>
            </div>
            <div class="department-list">
                <el-table
                        :data="tableData"
                        header-row-class-name="table-header"
                        style="width: 100%">
                    <el-table-column
                            prop="id"
                            label="ID"
                            width="240">
                        <template slot-scope="scope">
                            <div class="table-id">
                                {{scope.row.id}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="部门名称">
                        <template slot-scope="scope">
                            <div class="table-name">
                                {{scope.row.depart_name}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="小组名称">
                        <template slot-scope="scope">
                            <div class="table-name">
                                {{scope.row.group_name}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="num"
                            label="人数">
                        <template slot-scope="scope">
                            <div class="table-num">
                                {{scope.row.num}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="operate"
                            label="操作">
                        <template slot-scope="scope">
                            <div class="table-operate">
                                <el-button @click="showEditDialog(scope.row)" type="text">修改</el-button>
                                <el-button class="delete" @click="showDelDialog(scope.row.id)" type="text">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--        <el-pagination-->
                <!--          class="page-all"-->
                <!--          background-->
                <!--          :page-size="10"-->
                <!--          layout="prev, pager, next"-->
                <!--          :total="tableData.length">-->
                <!--        </el-pagination>-->
            </div>
        </div>
        <el-dialog
                :visible.sync="editVisible"
                width="30%"
                center
                :before-close="closeEdit">
            <span slot="title" class="dialog-title">添加小组</span>
            <div class="dialog-container flex-center">
                <div>所属部门</div>
                <el-select v-model="edit_department" placeholder="请选择部门">
                    <el-option
                            v-for="item in departmentSelect"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="dialog-container flex-center">
                <div>小组名称</div>
                <el-input v-model="edit_value" placeholder="请输入小组名称"></el-input>
            </div>
            <div class="dialog-container flex-center">
                <div>验证码<span style="color: #ffffff">吗</span></div>
                <el-input v-model="edit_code" type="number" placeholder="请输入验证码"></el-input>
                <el-button class="margin-left" type="primary" @click="sendCode">{{codeText}}</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
                :visible.sync="delVisible"
                width="30%"
                center
                :before-close="closeEdit">
            <span slot="title" class="dialog-title">温馨提示</span>
            <div class="dialog-container">
                <div>此操作将永久删除该小组, 是否继续?</div>
            </div>
            <div class="dialog-container flex-center">
                <div>验证码<span style="color: #ffffff">吗</span></div>
                <el-input v-model="edit_code" type="number" placeholder="请输入验证码"></el-input>
                <el-button class="margin-left" type="primary" @click="sendCode">{{codeText}}</el-button>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deleteItem">确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";

    export default {
        components: {
            ElInput,
            ElButton
        },
        name: "team",
        data() {
            return {
                edit_department: "",
                departmentOptions: [],
                search_content: '',
                editVisible: false,
                delVisible: false,
                cur_id: -1,
                edit_value: '',
                edit_code: '',
                tableData: [],
                id: null, // 计时器id
                timeId: null, // 一次性计时器id
                codeText: '获取验证码', // 验证码文字
            }
        },

        created() {
            this.$emit('setIndex', [2, 4])
            this.init()
            this.getList()
        },
        methods: {
            search() {
                this.getList()
            },
            deleteItem() {
                if (!this.edit_code) {
                    this.$message.error("请输入验证码")
                    return
                }
                let that = this
                that.$api.apiContent.groupDel({
                    token: that.$store.state.user.token,
                    timestamp: that.$tools.requestTime(),
                    id: this.cur_id,
                    code: this.edit_code,
                    sign: that.$tools.SHA256({
                        token: that.$store.state.user.token,
                        timestamp: that.$tools.requestTime(),
                        id: this.cur_id,
                        code: this.edit_code,
                    }, that.$store.state.user.key)
                }).then(() => {
                    that.$message({
                        message: '删除成功',
                        type: 'success',
                        duration: 2000
                    });
                    that.getList()
                    that.closeEdit()
                }).catch((e)=>{
                    that.$message.error(e.msg);
                })
            },
            showEditDialog(data) {
                this.editVisible = true
                if (data) {
                    this.cur_id = data.id
                    this.edit_value = data.group_name
                    this.edit_department = data.did
                }
            },
            showDelDialog(id) {
                this.delVisible = true
                this.cur_id = id
            },
            closeEdit() {
                this.editVisible = false
                this.delVisible = false
                this.cur_id = -1
                this.edit_value = ''
                this.edit_code = ''
            },
            //  获取验证码
            sendCode() {
                if (this.codeText === '获取验证码') {
                    this.$api.apiContent.SendMsg({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        }, this.$store.state.user.key)
                    }).then(() => {
                        this.codeText = 60
                        this.id = setInterval(() => {
                            if (this.codeText === 0) {
                                this.codeText = '获取验证码'
                                return
                            }
                            if (typeof this.codeText === 'number') {
                                this.codeText--
                            }
                        }, 1000)
                    }).catch(e => {
                        this.$message.error(e.msg)
                        this.codeText = '获取验证码'
                        clearInterval(this.id)
                    })
                } else {
                    this.$message.error('请勿重复发送')
                }
            },
            submit() {
                if (!this.edit_department) {
                    this.$message.error("请选择部门")
                    return
                }
                if (!this.edit_value) {
                    this.$message.error("请输入小组名称")
                    return
                }
                if (!this.edit_code) {
                    this.$message.error("请输入验证码")
                    return
                }
                if (this.cur_id != -1) {//修改
                    this.$api.apiContent.groupEdit({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.cur_id,
                        name: this.edit_value,
                        did: this.edit_department,
                        code: this.edit_code,
                        sign: this.$tools.SHA256({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.cur_id,
                            name: this.edit_value,
                            did: this.edit_department,
                            code: this.edit_code,
                        }, this.$store.state.user.key)
                    }).then(() => {
                        this.getList()
                        this.closeEdit()
                    }).catch((e)=>{
                        this.$message.error(e.msg);
                    })
                } else { //添加
                    this.$api.apiContent.groupAdd({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        name: this.edit_value,
                        did: this.edit_department,
                        code: this.edit_code,
                        sign: this.$tools.SHA256({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            name: this.edit_value,
                            did: this.edit_department,
                            code: this.edit_code,
                        }, this.$store.state.user.key)
                    }).then(() => {
                        this.getList()
                        this.closeEdit()
                    }).catch((e)=>{
                        this.$message.error(e.msg);
                    })
                }
            },
            init() {
                this.$api.apiContent
                    .bumen({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.departmentOptions = res.data
                    })
                    .catch(() => {
                        this.departmentOptions = [];
                    });
            },
            getList(){
                this.$api.apiContent.groupList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    name: this.search_content,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        name: this.search_content,
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.tableData = res.data
                    this.cur_id = -1
                    this.edit_value = ''
                }).catch(() => {
                    this.tableData = []
                    this.cur_id = -1
                    this.edit_value = ''
                })
            }
        },
        computed: {
            departmentSelect: function () {
                return this.departmentOptions.filter(function (item) {
                    return item.id !== 0;
                });
            },
        },
    }
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }

            }
        }

        .dialog-container {
            margin-bottom: 20px;

            div {
                flex-shrink: 0;
                font-size: 18px;
                color: #333333;
            }

            .el-input,.el-select  {
                flex-shrink: 1;
                width: 100%;
                margin-left: 20px;
            }
        }

        .dialog-title {
            color: #000;
            font-size: 24px;
        }

        .dialog-footer {
            .el-button {
                width: 235px;
            }
        }

        .department-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .department-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .el-input {
                    width: 268px;
                }
            }

            .department-list {
                padding: 34px 28px;
                min-height: 700px;

                .table-id {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-name {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-num {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-operate {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .delete {
                        color: red;
                        margin-left: 30px;
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }
</style>